<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div v-if="isLoading">
        <div style="overflow-y: auto; white-space: nowrap;" class="p-3 cust_card">
          <div class="heading-3 text-text1 mb-2">● Support Detail:</div>
          <!-- <div class="heading-3 text-text1">● Basic Detail:</div> -->
          <div>
            <div class="overflow-auto">
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:240px">Organization Name</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-primary heading-5 cursor-pointer" v-if="compDetail.orgName !== ''" @click="$router.push({name: 'OrganizationDetail', params: {orgId: compDetail.organizationDetailId}})">{{compDetail.orgName}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:240px">User Name</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.userName !== ''">{{compDetail.userName}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:240px">Email</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.emailAddress !== ''">{{compDetail.emailAddress}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:240px">Created date</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.addedDate !== ''">{{compDetail.addedDate | dateTimeWithName}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:240px">isClose</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.issueClose === 'false'">
                      <p class=" text-red-700 heading-5  border border-red-600 rounded-md px-3">Open</p>
                    </span>
                    <span class="text-text1 heading-4" v-else>
                      <p class=" text-green-700 heading-5  border border-green-600 rounded-md px-3">Close</p>
                    </span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:240px">Notes</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="mb-0 whitespace-pre-line text-text1 heading-5">
                      {{compDetail.notes === '' ? '-' : (compDetail.notes.length > 200)? readMoreNote ?  compDetail.notes : compDetail.notes.substr(0, 200) : compDetail.notes}}
                      <span v-if="compDetail.notes.length > 200" class="text-primary cursor-pointer heading-7" @click="readMoreNote = !readMoreNote">
                        {{readMoreNote === false ? 'Read More...' : 'Read Less...'}}</span>
                    </span>
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div style="overflow-y: auto; white-space: nowrap;" class="p-3 cust_card mt-2">
          <div class="heading-3 text-text1 mb-2">● Device Detail:</div>
          <!-- <div class="heading-3 text-text1">● Basic Detail:</div> -->
          <div class="grid grid-cols-6 overflow-auto">
            <div class="col-span-6 xl:col-span-3 2xl:col-span-3">
              <div class="">
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">App Version</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.appVersion !== ''">{{compDetail.appVersion}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Build Version</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.buildVersion !== ''">{{compDetail.buildVersion}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Device Id</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.deviceUniqueId !== ''">{{compDetail.deviceUniqueId}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Brand</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.deviceBrand !== ''">{{compDetail.deviceBrand}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Model</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.deviceModel !== ''">{{compDetail.deviceModel}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Operating System(OS)</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.os !== ''">{{compDetail.os}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">OS Version</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.osVersion !== ''">{{compDetail.osVersion}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-span-3 xl:col-span-3 2xl:col-span-3">
              <div class="">
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Local Time of device</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.deviceLocalTime !== ''">{{compDetail.deviceLocalTime | dateTimeWithName}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Resolution</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.deviceResolution !== ''">{{compDetail.deviceResolution}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">IP</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.ipAddress !== ''">{{compDetail.ipAddress}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Device Connectivity</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.deviceConnectivityOn !== ''">{{compDetail.deviceConnectivityOn}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Battery Level</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.batteryLevel !== ''">{{ Math.abs(compDetail.batteryLevel)}} <i class="fa-solid fa-percent text-gray4 heading-7"></i></span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-semibold"><p class="mb-0" style="width:210px">Internet Speed</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="compDetail.internetSpeed !== ''">{{compDetail.internetSpeed}}</span>
                    <span class="text-text1 heading-5" v-else>-</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="p-1">
              <div class="mb-0">
                <span class="text-text2 heading-5 font-semibold"><p class="mb-0 pb-1" style="width:210px">App permissions:</p></span>
                <div class="pl-2 mt-1 flex">
                  <li class="heading-5 text-text2 w-60">Camera</li>
                  <span class="heading-4" v-if="compDetail.isCameraPermission"><i class="fas fa-check text-success"></i></span>
                  <span class="heading-4" v-if="!compDetail.isCameraPermission"><i class="fas fa-times text-error"></i></span>
                </div>
                <div class="pl-2 mt-1 flex">
                  <li class="heading-5 text-text2 w-60">Contacts</li>
                  <span class="heading-4" v-if="compDetail.isContactsPermission"><i class="fas fa-check text-success"></i></span>
                  <span class="heading-4" v-if="!compDetail.isContactsPermission"><i class="fas fa-times text-error"></i></span>
                </div>
                <div class="pl-2 mt-1 flex">
                  <li class="heading-5 text-text2 w-60">Files</li>
                  <span class="heading-4" v-if="compDetail.isFilePermission"><i class="fas fa-check text-success"></i></span>
                  <span class="heading-4" v-if="!compDetail.isFilePermission"><i class="fas fa-times text-error"></i></span>
                </div>
                <div class="pl-2 mt-1 flex">
                  <li class="heading-5 text-text2 w-60">Location</li>
                  <span class="heading-4" v-if="compDetail.isLocationPermission"><i class="fas fa-check text-success"></i></span>
                  <span class="heading-4" v-if="!compDetail.isLocationPermission"><i class="fas fa-times text-error"></i></span>
                </div>
                <div class="pl-2 mt-1 flex">
                  <li class="heading-5 text-text2 w-60">Microphone</li>
                  <span class="heading-4" v-if="compDetail.isMicPermission"><i class="fas fa-check text-success"></i></span>
                  <span class="heading-4" v-if="!compDetail.isMicPermission"><i class="fas fa-times text-error"></i></span>
                </div>
                <div class="pl-2 mt-1 flex">
                  <li class="heading-5 text-text2 w-60">Notifications</li>
                  <span class="heading-4" v-if="compDetail.isNotificationPermission"><i class="fas fa-check text-success"></i></span>
                  <span class="heading-4" v-if="!compDetail.isNotificationPermission"><i class="fas fa-times text-error"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div class="px-1 border border-gray2 rounded-lg p-1 max-w-max heading-6" v-if="locationObj !== null">
            <div class="flex items-start" @click="openMap(locationObj.latitude, locationObj.longitude)">
              <span class="cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i></span>
              <div class="flex pr-2 font-semibold text-text2 cursor-pointer">
                Click here to show Device location <span class="text-gray4 pl-1 heading-7 self-center"> (Accuracy: {{ locationObj.accuracy}})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-show="scY > 200" @click="toTop" class="bg-primary rounded-full h-10 w-10 fixed bottom-3 right-2"><i class="fas fa-caret-up text-white"></i></button>
  </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
  },
  mixins: [deboucneMixin],
  data () {
    return {
      readMoreNote: false,
      connect: [
        {title: 'Camera', isSelect: true},
        {title: 'Contacts', isSelect: false},
        {title: 'Location', isSelect: true},
        {title: 'Microphone', isSelect: false},
        {title: 'Nearby Device', isSelect: false},
        {title: 'Phone', isSelect: true},
        {title: 'Photos and videos', isSelect: false},
        {title: 'Notifications', isSelect: true},
      ],
      isLoading: false,
      compDetail: [],
      locationObj: {},
      scY: 0,
      scTimer: 0,
      useDetailId: 0,
      isShowFullDesc: true,
      isShowShortDesc: true,
      mobileView: false,
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    window.scrollTo({top: 0})
    window.addEventListener('scroll', this.handleScroll);
    this.resizeWindowHandler();
    setTimeout(() => {
      document.title = 'Support Detail'
    }, 1000)
    this.getCompetDetail()
  },
  methods: {
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
    smoothScroll (target) {
      window.scrollTo({
        top: document.getElementById(target).offsetTop - 60,
        left: 0,
        behavior: "smooth",
      });
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop () {
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    redirectToList() {
      // this.$router.push({name: 'Competition'})
      this.$router.go(-1)
    },
    getCompetDetail () {
      this.isLoading = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetSupportDetail(
        parseInt(this.$route.params.supid),
        response => {
          this.compDetail = response.Data === null ? [] : response.Data
          this.locationObj = response.Data.locationObj !== "" ? JSON.parse(response.Data.locationObj) : null
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.isLoading = true
        },
        error => {
          this.isLoading = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  }
}
</script>
<style scoped>

.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.list_top_card_btn {
  width: 83px !important;
  margin-right: 15px !important;
}
.ImageTag {
  max-height: 300px;
  width: auto;
}
</style>
